













































.glitch {
  color: white;
  position: relative;

  //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //background: red;

  &:before, &:after {
    color: white;
    content: attr(title);
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 2px;
    line-height: 100%;
    background: #121314;
    z-index: 1;
  }
  &:before {
    left: 1px;
    text-shadow: -1px 0 #ff0102;
  }
  &:after {
    left: -1px;
    text-shadow: 1px 0 #00feff;
  }

  &.animated {
    &:before, &:after {
      animation-duration: 6s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
    &:before {
      animation-name: glitch-animation-1;
    }
    &:after {
      animation-name: glitch-animation-2;
    }
    &.alt:before {
      animation-name: glitch-animation-3;
    }
    &.alt:after {
      animation-name: glitch-animation-4;
    }
  }
  //:not(.animated) {
    &:before {
      clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
    }
    &:after {
      clip-path: polygon(0 30%, 100% 30%, 100% 50%, 0 50%);
    }
  //}
}

@for $j from 1 through 4 {
  @keyframes glitch-animation-#{$j} {
    $steps: 20;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        $top: percentage(random(100)/100);
        $bottom: percentage(random(100)/100);
        clip-path: polygon(0 $top, 100% $top, 100% $bottom, 0 $bottom);
      }
    }
  }
}
