@font-face {
  font-family: 'Iosevka Aile Web';
  font-display: swap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  src: url(./iosevka-aile-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Iosevka Aile Web';
  font-display: swap;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  src: url(./iosevka-aile-extrabold.woff2) format('woff2');
}

@font-face {
  font-family: 'Iosevka Web';
  font-display: swap;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  src: url(./iosevka-light.woff2) format('woff2');
}

@font-face {
  font-family: 'Iosevka Web';
  font-display: swap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  src: url(./iosevka-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Iosevka Web';
  font-display: swap;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  src: url(./iosevka-bold.woff2) format('woff2');
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


:root {
  /* Base values */
  --fontSize: 18px;
  --spacing: 1.778rem; /* 1.778rem * 18px = 32px */
  --stepsize: 32;

  --border__width--default: 0.111rem; /* 2px */

  --spacing-2: calc(2 * var(--spacing));
  --spacing-3: calc(3 * var(--spacing));
}

html {
  font-size: var(--fontSize);
}

body {
  line-height: var(--spacing);
}


h1 {
  padding-top: var(--spacing);
  margin-bottom: var(--spacing);
  line-height: var(--spacing);
  font-size: calc(2 * var(--fontSize));
  position: relative;
  top: calc(0.7 * var(--spacing));
}

h2 {
  padding-top: var(--spacing);
  line-height: var(--spacing);
  font-size: calc(1.4 * var(--fontSize));
  position: relative;
  top: calc(-0.1 * var(--spacing));
}


body {
  background-color: #121314;
  color: #e3e4e5;
  font-family: "Iosevka Aile Web";
}

.site-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  padding: 0 1em 1em;
}

nav, aside, main > *:not(.vlow-canvas), .squish {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

header {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -100px;
    bottom: -80px;
    z-index: -1;
    left: -1em;
    right: -1em;
    background: center bottom/auto 200px linear-gradient(0deg, #121314 0%, transparent 100%) repeat-x,
    center -29px/64px 64px url(/static/bg.svg);
    overflow: hidden;
    margin-left: -65px;
    min-height: 260px;
  }

  nav {
    display: flex;
    justify-content: space-between;

    border-top: 1px solid rgba(227, 228, 229, 0.5);
    margin-top: calc(var(--spacing) / 2);

    &, > * {
      pointer-events: none;
    }

    a {
      pointer-events: all !important;
    }

    a:hover:before,
    &:not(:hover) a.router-link-exact-active:before {
      position: absolute;
      pointer-events: none;
      content: "";
      width: 100%;
      border-top: 2px solid rgba(227, 228, 229, 0.99);
      top: -7px;
    }


    a {
      cursor: default;

      &[href] {
        cursor: pointer;
      }
    }

    .dim {
      opacity: 0.5;
      position: absolute;
    }

    a.router-link-exact-active {
      &[data-shadow]:after {
        content: attr(data-shadow);
      }

      &:after {
        position: absolute;
        z-index: 0;
        left: -5em;
        right: -5em;
        text-align: center;
        white-space: nowrap;
        filter: blur(2px);
        font-size: 2em;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    ul {
      display: flex;
    }

    a {
      text-decoration: none;
      color: white;
      position: relative;
    }

    ul {
      list-style: none;
    }

    ul > li {
      margin-left: 1em;
    }

    ul sup {
      font-size: 0.5em;
      position: relative;
      line-height: 0;
    }
  }
}


header .vlow-canvas {
  margin: var(--spacing) 0;
}

main .vlow-canvas:before {
  content: "";
  display: block;
  background: center top / auto 30px linear-gradient(0deg, transparent 0%, #121314 100%) repeat-x,
  center bottom / auto 30px linear-gradient(0deg, #121314 0%, transparent 100%) repeat-x,
  center 6px / 64px 64px url(./bg.svg);
  position: absolute;
  left: -1em;
  right: -1em;
  top: calc(-0.5 * var(--spacing));
  bottom: calc(-0.5 * var(--spacing));
  z-index: -1;
  margin-left: -65px;
}

.debug {
  background-position-y: 3px;
  background: #121314 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAgCAYAAADT5RIaAAAAD0lEQVQImWMYxODbtMr/AAgrAwXH4+DiAAAAAElFTkSuQmCC) repeat local;
}


:root {
  --shf-two-digits: 4.9406564584124654e-322;
  --shf-one-digit: 4.9406564584124654e-323;
  --shf-whole-number: 4.9406564584124654e-324;
}

.vlow-canvas {
  --vlow-node-title-height: 24px;
  --vlow-node-row-height: 24px;

  height: calc((var(--initial-height) / var(--stepsize)) * var(--shf-whole-number) / var(--shf-whole-number) * var(--stepsize) * 1px);
  line-height: var(--vlow-node-row-height);
}

.vlow-canvas {
  user-select: none;
}

code {
  font-family: "Iosevka Web";
}

.vlow-node {
  font-family: "Iosevka Web";
  line-height: calc(var(--vlow-node-title-height) * 1px);
  font-size: 1.3ex;

  .title {
    text-align: center;
    background: rgb(69 72 72);

    position: relative;

    &:before {
      position: absolute;
      content: "";
      border: 3px solid;
      border-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 9 9' width='9' height='9'%3E%3Cpath fill='%23ccc' d='M 0, 0 m1,1v-1h1v1h1v1h-1v1h-1v-1h-1v-1z' /%3E%3Cpath fill='%23ccc' d='M 6, 0 m1,1v-1h1v1h1v1h-1v1h-1v-1h-1v-1z' /%3E%3Cpath fill='%23ccc' d='M 0, 6 m1,1v-1h1v1h1v1h-1v1h-1v-1h-1v-1z' /%3E%3Cpath fill='%23ccc' d='M 6, 6 m1,1v-1h1v1h1v1h-1v1h-1v-1h-1v-1z' /%3E%3C/svg%3E") 3 / 3px / 2px stretch;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.has-left:before,
  &.has-right:after,
  &.vlow-node-decision-table:before {
    content: "";
    display: block;
    background: rgba(33, 34, 35, 0.8);
    z-index: -1;
    grid-row: 2/-1;
  }

  &.has-left:before {
    grid-column: 1/4;
  }

  &.has-right:after {
    grid-column: -4/-1;
  }

  &.vlow-node-decision-table:before {
    grid-column: 1/-1;
  }


  .content {
    /* border-bottom: 1px solid rgba(33, 34, 35, 0.8); */
    background: rgba(33, 34, 35, 0.3);
  }

  .value {
    padding-left: 5px;
    font-family: "Iosevka Aile Web";
  }
}

main, aside {
  a {
    color: white;
    text-decoration: none;

    position: relative;

    text-shadow: 2px 0 0 #121314, 0 2px 0 #121314, -2px 0 0 #121314, 0 -2px 0 #121314;

    &:before {
      content: "";
      display: block;
      position: absolute;
      border-bottom: 2px solid rgba(227, 228, 229, 0.99);
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }


    &:visited {
      //text-decoration-thickness: 1px;
      //text-decoration-color: rgba(227, 228, 229, 0.5);
    }
  }

  p {
    margin-bottom: var(--spacing);
  }
}

time {
  float: right;
  font-weight: 300;
  font-family: 'Iosevka Web';
  font-size: 1.3ex;
  letter-spacing: .02em;
  margin-top: 4px;
}

a.header-anchor {
  position: absolute;
  left: -1em;

  font-size: 1.3ex;

  font-weight: 200;
  font-family: 'Iosevka Web';

  &:before {
    content: none;
  }
}


.justify {
  text-align: justify;
  text-align-last: left;
  @media (min-width: 967px) {
    &.fullwidth {
      text-align-last: justify;
    }
  }
}

blockquote {
  margin-left: 1em;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid white;
    left: -0.5em;
  }
}

ul, ol {
  margin-left: 1em;
}

hr {
  margin: 12px 0;
  border: 0 none;
  border-top: 8px solid;
  border-image-source: url("hr.svg");
  border-image-slice: 6 43 0 43;
  border-image-width: 6px 43px 0px 43px;
  border-image-repeat: repeat;
}
